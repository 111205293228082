export default function min(value: string|number|null, config: number): boolean {
    if (value === null) {
        return false;
    }

    if (typeof value === "string") {
        value = parseInt(value);

        if (isNaN(value)) {
            return false;
        }
    }

    return value >= config;
}
