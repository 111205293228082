// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function required(value: any): boolean {
    if (typeof value === 'number') {
        return true;
    } else if (Array.isArray(value)) {
        return value.length > 0;
    }

    return !!value;
}
