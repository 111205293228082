import city        from '@/utils/validator/rules/city';
import company     from '@/utils/validator/rules/company';
import email       from '@/utils/validator/rules/email';
import equals      from '@/utils/validator/rules/equals';
import lettersOnly from '@/utils/validator/rules/letters-only';
import max         from '@/utils/validator/rules/max';
import maxLength   from '@/utils/validator/rules/max-length';
import message     from '@/utils/validator/rules/message';
import min         from '@/utils/validator/rules/min';
import minLength   from '@/utils/validator/rules/min-length';
import numeric     from '@/utils/validator/rules/numeric';
import phone       from '@/utils/validator/rules/phone';
import required    from '@/utils/validator/rules/required';
import salutation  from '@/utils/validator/rules/salutation';
import street      from '@/utils/validator/rules/street';
import taxNumber   from '@/utils/validator/rules/tax-number';
import zipCode     from '@/utils/validator/rules/zip-code';

interface ValidationRule {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any, config?: any): boolean;
}

export const validationCommon: Record<string, ValidationRule> = {
    city,
    company,
    email,
    equals,
    lettersOnly,
    maxLength,
    message,
    minLength,
    numeric,
    phone,
    required,
    min,
    max,
    street,
    taxNumber,
    zipCode,
    salutation,
};

type Value = string | number | boolean | null | undefined;

type ValidationName = keyof typeof validationCommon;

export interface Rule {
    validation: ValidationName;
    message: string;
    config?: string | number | null;
}

export type RuleSet = Rule[];

function validate(value: Value, rules: RuleSet): string | null {
    for (const ruleKey in rules) {
        const rule = rules[ruleKey];
        if (typeof validationCommon[rule.validation] === 'undefined') {
            throw new Error(`The validation rule "${rule.validation}" does not exist.`);
        }

        if (!validationCommon[rule.validation](value, rule.config)) {
            return rule.message;
        }
    }

    return null;
}

export default validate;
