export default function street(value: string|null, countryId: number): boolean {
    const countriesRequiringHousenumber = [
        1, // Germany
        2, // France
        3, // Belgium
        4, // Luxembourg
        5, // Sweden
        6, // Great Britain
        7, // Switzerland
        8, // Austria
        92, // Italy
    ];

    let regex = /^(?=.*)(?=.*[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F])([\w\s'’,\.:\-\/\(\)\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F]+)$/i;
    if (countriesRequiringHousenumber.includes(countryId)) {
        regex = /^(?=.*[0-9])(?=.*[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F])([\w\s'’,\.:\-\/\(\)\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F]+)$/i;
    }

    return !value || regex.test(value);
}
