import { Country }      from '@/types/country';
import { CountryState } from '@/types/countryState';

export enum AddressType {
    DEFAULT = 1,
    DELIVERY = 2,
    INVOICE = 3,
    CONTACT = 4,
}

export enum GenderType {
    MALE,
    FEMALE,
    OTHER,
}

export interface Address {
    id: number;
    addressType: AddressType;
    country: Country;
    salutation: GenderType;
    company: string;
    firstName: string;
    lastName: string;
    street: string;
    zip: string;
    city: string;
    validCompanyName: boolean | null;
    taxNumber: string | null;
    projectName: string | null;
    countryState: CountryState;
    phone: string;
    phoneAdditional: string | null;
}

export interface AddressCreatePayload {
    addressType: AddressType;
    salutation: GenderType;
    firstName: string;
    lastName: string;
    company: string | null;
    taxNumber: string | null;
    street: string;
    zip: string;
    city: string;
    countryState: number | null;
    country: number;
    countryCallingCode: string;
    mobilePhone: string;
    countryCallingCodeAdditional?: string | null;
    mobilePhoneAdditional?: string | null;
    projectName?: string;
}

export interface AddressUpdatePayload extends AddressCreatePayload {
    id: number;
}

export type AddressResponse = Address;
