export default function email(value: string|null): boolean {
    if (!value) {
        return true;
    }

    const maxLength = 254;

    // Total length of email can not surpass 254 characters
    if (value.length > maxLength) {
        return false;
    }

    // Split local and domain parts
    const [localPart, domainPart] = value.split('@');

    // We must have both local and domain parts in the email
    if (localPart === undefined || domainPart === undefined || localPart.length === 0 || domainPart.length === 0) {
        return false;
    }

    // Split every subdomain and TLD
    const domainParts = domainPart.split('.');
    if (domainParts.length < 2) {
        return false;
    }

    const tldPart = domainParts.pop() as string;

    return (
        isValidLocalPart(localPart) &&
        isValidDomainParts(domainParts) &&
        isValidTldPart(tldPart)
    );
}

function isValidLocalPart(localPart: string): boolean {
    const localPartRegex = /^(?!.*\.\.)([a-zA-Z\d])(?:[a-zA-Z\d.+_-]{0,62}?([a-zA-Z\d+]))?$/u;

    return localPartRegex.test(localPart);
}

function isValidDomainParts(domainParts: string[]): boolean {
    const domainPartRegex = /^[\p{L}\d](?:[\p{L}\d-]{0,61}[\p{L}\d])?$/u;

    return !domainParts.some(part => !domainPartRegex.test(part));
}

function isValidTldPart(tldPart: string): boolean {
    const tldPartRegex = /^[a-zA-Z][a-zA-Z\d-]{0,61}[a-zA-Z\d]$/u;

    return tldPartRegex.test(tldPart);
}
