export default function zipCode(value: string|null, countryId: number): boolean {
    /**
     * IMPORTANT: These are not the IDs for shops. These are primary keys of {@see Country}.
     *
     * 1:  Germany
     * 2:  France
     * 3:  Belgium
     * 4:  Luxembourg
     * 5:  Sweden
     * 6:  Great Britain
     * 7:  Switzerland
     * 8:  Austria
     * 92: Italy
     */

    switch (countryId) {
        case 1:
        case 2:
        case 92:
            return !value || /^[0-9]{5}$/i.test(value);
        case 5:
            return !value || /^(s-|S-)?[0-9]{3}\s?[0-9]{2}$/i.test(value);
        // Validation for the United Kingdom postal codes ─ Using default validation for now
        // Kept around due to the complexity of UK postal codes
        // case 6:
        //     return !value || /^(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{2})$/i.test(value);
        case 3:
        case 4:
        case 7:
        case 8:
            return !value || /^[0-9]{4}$/i.test(value);
        default:
            return !value || /^.{1,25}$/i.test(value);
    }
}
